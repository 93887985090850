
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import CustomPageSectionComponent from 'components/customPageSectionComponent'
import SocialLinks from 'components/socialLinks'
import SEO from 'components/seo'
import ContactFormButton from 'components/contactFormButton.js'
import { jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  pageWrapper,
  noFadeHeadingWrapper,
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Cloud Security Assessment" }) {
        edges {
          node {
            professionalChildrenFieldGroup {
              childPageHeader
              childPageHeaderImage {
                sourceUrl
              }
              childPageSubtext
              childPageCtaText
              childPageCtaLink
              whatIsSectionDescription
              whatIsSectionHeader
              whatIsSectionSubheader
              ourApproachSectionDescription
              ourApproachSectionHeader
              ourApproachSectionSubheader
              keyDeliverablesSectionDescription
              keyDeliverablesSectionHeader
              keyDeliverablesSectionSubheader
              whyDeimosSectionDescription
              whyDeimosSectionHeader
              whyDeimosSectionSubheader
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const CloudSecurityAssessment = ({ data, pageContext }) => {
  const childPageData = data.wordPress.pages.edges[0].node.professionalChildrenFieldGroup
  const childPageHeaderImage = childPageData.childPageHeaderImage.sourceUrl
  const whatIsSectionContent = childPageData.whatIsSectionDescription
  const whatIsSectionHeader = childPageData.whatIsSectionHeader
  const whatIsSectionSubheader = childPageData.whatIsSectionSubheader
  const ourApproachSectionDescription = childPageData.ourApproachSectionDescription
  const ourApproachSectionHeader = childPageData.ourApproachSectionHeader
  const ourApproachSectionSubheader = childPageData.ourApproachSectionSubheader
  const keyDeliverablesSectionContent = childPageData.keyDeliverablesSectionDescription
  const keyDeliverablesSectionHeader = childPageData.keyDeliverablesSectionHeader
  const keyDeliverablesSectionSubheader = childPageData.keyDeliverablesSectionSubheader
  const whyDeimosSectionContent = childPageData.whyDeimosSectionDescription
  const whyDeimosSectionHeader = childPageData.whyDeimosSectionHeader
  const whyDeimosSectionSubheader = childPageData.whyDeimosSectionSubheader
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services`,
            'name': 'Professional Services',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/professional-services/cloud-security`,
            'name': 'Cloud Security',
            'position': 3
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 4
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Cloud Security Assessment Information'
        title='Cloud Security Assessment'
      />
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Cloud Security Assessments'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
        <div
          className={noFadeHeadingWrapper}
          style={{ backgroundImage: `url(${childPageHeaderImage})` }}>
          <h1>{childPageData.childPageHeader}</h1>
          <p>{childPageData.childPageSubtext}</p>
          <ContactFormButton ctaText={childPageData.childPageCtaText} />
        </div>
        <CustomPageSectionComponent
          hasWhiteBackground
          isProfessionalServiceSubpage
          items={whatIsSectionContent}
          subtitle={whatIsSectionSubheader}
          title={whatIsSectionHeader}
        />
        <CustomPageSectionComponent
          isProfessionalServiceSubpage
          items={ourApproachSectionDescription}
          subtitle={ourApproachSectionSubheader}
          title={ourApproachSectionHeader}
        />
        <CustomPageSectionComponent
          hasWhiteBackground
          isProfessionalServiceSubpage
          items={keyDeliverablesSectionContent}
          subtitle={keyDeliverablesSectionSubheader}
          title={keyDeliverablesSectionHeader}
        />
        <CustomPageSectionComponent
          isProfessionalServiceSubpage
          items={whyDeimosSectionContent}
          subtitle={whyDeimosSectionSubheader}
          title={whyDeimosSectionHeader}
        />
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

CloudSecurityAssessment.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CloudSecurityAssessment
